import {
    QUOTE_RECEIVED,
    PERSONAL_DATA_RECEIVED,
    EMAIL_SENT,
    CLEAR_STATE,
    ACCEPT_COOKIES,
} from '../constants/action-types';

const actions = (state = { quoteReceived: false, resetNow: false, cookieSet: false }, action) => {
    switch (action.type) {
        case QUOTE_RECEIVED:
            return {
                ...state,
                quoteSelection: action.quote,
                quoteReceived: true,
            }
        case EMAIL_SENT:
            return {
                quoteReceived: false,
            }
        case PERSONAL_DATA_RECEIVED:
            return {
                ...state,
                name: action.data.name,
                email: action.data.email
            }
        case CLEAR_STATE:
            return {
                ...state,
                resetNow: action.state
            }
        case ACCEPT_COOKIES:
            return {
                ...state,
                cookieSet: true,
            }
        default:
            return state;
    }
}

export default actions;
