import React from 'react';
import { Provider } from 'react-redux';
import {applyMiddleware, compose, createStore as reduxCreateStore} from 'redux';
import thunk from "redux-thunk";

import storeReducer from "./index";

const composeEnhancers = typeof window !== 'undefined' && process.env.DEVELOPMENT ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const enhancer = composeEnhancers(
    applyMiddleware(thunk)
);

const store = reduxCreateStore(storeReducer, enhancer);

const ReduxWrapper = ({ element }) => (
    <Provider store={store}>{element}</Provider>
);

export default ReduxWrapper;