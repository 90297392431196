const QUOTE_RECEIVED = 'QUOTE_RECEIVED';
const EMAIL_SENT = 'EMAIL_SENT';
const PERSONAL_DATA_RECEIVED = 'PERSONAL_DATA_RECEIVED';
const CLEAR_STATE = 'CLEAR_STATE';
const ACCEPT_COOKIES = 'ACCEPT_COOKIES';

export {
  QUOTE_RECEIVED,
  EMAIL_SENT,
  PERSONAL_DATA_RECEIVED,
  CLEAR_STATE,
  ACCEPT_COOKIES,
}
